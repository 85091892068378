<template>
    <s-form @submit="formSuccess">
        <div class="row">
            <div class="col-12">

                <validated-text-area label="Cancel Reason" name="Cancelled reason"
                                    v-model="model.cancelled_reason" :rules="{required: true}" />

                <btn border-radius="0" size="sm" icon="fa fa-save" text="Save" color="success"></btn>
            </div>
        </div>
    </s-form>
</template>

<script>
export default {
    name : 'CancelAuction',

    data () {
        return {
            model : {
                cancelled_reason : ''
            }

        };
    },

    methods : {
        formSuccess () {
            this.$emit('success', this.model);
        }
    }
};
</script>

<style scoped>

</style>
