<template>
    <inner-page-card heading="Live Auctions" class="of-h mt-3" :scrollbar="true" :header-bullet="true"
                     :horz-padding="false">

        <div class="mb-4">
            <live-auction-filter class="" @filterUpdate="updateTable"></live-auction-filter>
        </div>

        <!--Headers-->
        <div class="row mb-3 fs--1 font-inter-medium text-6  d-sm-none">
            <div class="col order-1 order-lg-1 ">
                <div class="pos-r  text-center ">
                    <!--                    <span class="line-right"></span>-->
                    <div class="text-center ">Sl No</div>
                </div>
            </div>
            <div class="col order-1 order-lg-1 ">
                <div class="pos-r  text-center ">
                    <!--                    <span class="line-right"></span>-->
                    <div class="text-center ">Auction ID</div>
                </div>
            </div>
            <div class="col order-2 order-lg-2">
                <div class="pos-r">
                    <!--                    <span class="line-right"></span>-->
                    <div class="text-right">Account/Ref No.</div>
                </div>
            </div>
            <div class="col order-3 order-lg-3 d-sm-none">
                <div class="pos-r">
                    <!--                    <span class="line-right"></span>-->
                    <div class="text-right">Property Type</div>
                </div>
            </div>
            <div class="col order-4 order-lg-4">
                <div class="pos-r">
                    <!--                    <span class="line-right"></span>-->
                    <div class="text-right">Reserve Price</div>
                </div>
            </div>
            <div class="col order-5 order-lg-5 d-sm-none">
                <div class="pos-r">
                    <!--                    <span class="line-right"></span>-->
                    <div class="text-right">H1 Bid Amount</div>
                </div>
            </div>
            <div class="col order-6 order-lg-6 d-sm-none">
                <div class="pos-r">
                    <!--                    <span class="line-right"></span>-->
                    <div class="text-center">Branch</div>
                </div>
            </div>
            <div class="col order-7 order-lg-7 d-sm-none">
                <div class="pos-r">
                    <!--                    <span class="line-right"></span>-->
                    <div class="text-left">Auction Date</div>
                </div>
            </div>
            <div class="col order-8 order-lg-8 d-sm-none">
                <div class="pos-r">
                    <!--                    <span class="line-right"></span>-->
                    <div class="text-left">Start Time</div>
                </div>
            </div>
        </div>

        <div v-if="loading" class="text-center text-muted pt-3">
            <loading-animation></loading-animation>
            <p>Please wait while data is being loaded...</p>
        </div>

        <div v-if="details.data.length!==0 && !loading">
            <live-auction-item-card @cancel="cancelReason" @view="viewDetails"
                                    v-for="(item, index) in details.data" :key="item.id"
                                    :data-item="item" :index="index"
            ></live-auction-item-card>
        </div>

        <div v-if="details.data.length !== 0" class="mt-4 fl-te-c">
            <div>
                Showing {{ details.from }} to {{ details.to }} of {{ details.total }} entries
            </div>
            <div>
                <btn class="m-1" size="sm" @click="prevClicked" v-if="prev" type="button"
                     icon="fa fa-chevron-left"/>
                <btn class="m-1" size="sm" disabled v-else type="button" icon="fa fa-chevron-left"/>
                <btn class="m-1" size="sm" type="button">{{ details.current_page }}</btn>
                <btn class="m-1" size="sm" @click="nextClicked" v-if="next" type="button"
                     icon="fa fa-chevron-right"/>
                <btn class="m-1" size="sm" disabled v-else type="button" icon="fa fa-chevron-right"/>
            </div>
        </div>
        <div v-if="details.data.length===0 && !loading" class="text-center text-muted p-5">No live auctions going
            on.
        </div>

        <modal title="Cancel Auction" ref="cancelModal" width="50r" :no-close-on-backdrop="true"
               header-color="primary">
            <cancel-auction @success="cancelAuction"/>
        </modal>
    </inner-page-card>
</template>

<script>
import LiveAuctionItemCard from '../../components/auction/live-auction/LiveAuctionItemCard';
import LiveAuctionFilter from '../../components/auction/live-auction/LiveAuctionFilter';
import CancelAuction from '@/views/auction/cancel-auction/CancelAuction';
import axios from 'secure-axios';
import urls from '@/data/urls';
import { mapGetters } from 'vuex';

export default {
    name : 'LiveAuctionPage',

    components : { LiveAuctionFilter, LiveAuctionItemCard, CancelAuction },

    data () {
        return {
            prev     : '',
            next     : '',
            page     : '1',
            per_page : '10',
            details  : null,
            loading  : false,

            auctionID : null
        };
    },
    computed : {
        ...mapGetters(['currentUser'])
    },

    mounted () {
        this.loadDetails();
    },

    methods : {
        viewDetails (id) {
            this.$router.push('/live-auction-details/' + id + '/');
        },

        cancelReason (id) {
            this.auctionID = id;
            this.$refs.cancelModal.show();
        },

        async cancelAuction (model) {
            const that = this;
            this.$refs.cancelModal.close();
            this.loading = true;
            const sendModel = {
                ...model,
                id : this.auctionID
            };
            const response = await axios.form(urls.auction.auction.live.cancel, { ...sendModel }).catch(function (exception) {
                console.log('exception : ', exception);
                that.$notify('Could not cancel the auction, Please try again later', 'Failed', {
                    type : 'danger'
                });
                that.loading = false;
            });
            const json = response.data;
            if (json.error === false) {
                this.$notify('Successfully cancelled the auction.', 'Success', {
                    type : 'success'
                });
                this.loadDetails();
            } else {
                this.$notify('Could not cancel the auction, Please try again later', 'Failed', {
                    type : 'danger'
                });
            }
            this.loading = false;
        },

        loadDetails (model = null) {
            const that = this;
            that.loading = true;

            const params = this.setParams(model);
            if (that.currentUser.is_bank_admin) {
                params.bank = that.currentUser.bank.id;
            }

            params.page = this.page;
            params.per_page = this.per_page;

            axios.get(urls.auction.auction.live.list, {
                params : params
            }).then(function (response) {
                that.details = { ...response.data };
                that.setPagination();
                that.loading = false;
            }).catch(function (exception) {
                that.loading = false;
                console.log('exception occurred during the table loading : ', exception);
            });
        },

        setParams (model = null) {
            const params = {};

            if (model) {
                return {
                    ...model
                };
            } else {
                return params;
            }
        },

        setSort (key) {
            this.loadDetails(this.currentPage, null, key);
        },

        updateTable (model) {
            this.loadDetails(model);
        },
        setPagination () {
            this.prev = parseInt(this.details.current_page) !== this.details.from;
            this.next = parseInt(this.details.current_page) !== this.details.last_page;
        },
        prevClicked () {
            this.page = parseInt(this.page) - 1;
            this.loadData();
        },
        nextClicked () {
            this.page = parseInt(this.page) + 1;
            this.loadData();
        }
    }
};
</script>

<style scoped lang="scss">

</style>
