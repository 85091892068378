<template>
    <div class="bs-4 bb-1 border-r-0 c-card p-4">
        <div class="row d-sm-none d-md-none">
            <div class="col">
                <span class="font-inter-medium text-center">{{ index + 1 }}</span>
                <div class="line-right"></div>
            </div>
            <div class="col">
                <div class="text-center font-inter-medium">{{ dataItem.auction_id }}</div>
                <div class="line-right"></div>

            </div>
            <div class="col">
                <div class="text-center font-inter-medium">{{ dataItem.ref_no }}</div>
                <div class="line-right"></div>

            </div>
            <div class="col">
                <div class="text-center font-inter-medium">{{ dataItem.property_category }}</div>
                <div class="line-right"></div>

            </div>
            <div class="col">
                <div class="text-center font-inter-medium">₹ {{ dataItem.reverse_price }}</div>
                <div class="line-right"></div>

            </div>
            <div class="col">
                <div class="text-center font-inter-medium">₹ {{ dataItem.h1_bid_amount }}</div>
                <div class="line-right"></div>

            </div>
            <div class="col">
                <div class="text-center font-inter-medium">{{ dataItem.branch }}</div>
                <div class="line-right"></div>

            </div>
            <div class="col">
                <div class="text-center font-inter-medium">{{ dataItem.auction_start_date }}</div>
                <div class="line-right"></div>

            </div>
            <div class="col">
                <div class="text-center font-inter-medium">{{ dataItem.auction_start_time }} IST</div>
            </div>
            <div class="col-12 mt-5">
                <div class="fl-x fl-j-sb">
                    <div class="fl-x fl-a-c">
                        <span class="pr-2">
                            <img src="../../../assets/web/icons/timer-icon-24.png" alt="">
                        </span>
                        <span>Actual End Time:</span>
                        <span>{{ dataItem.auction_end_date }} {{ dataItem.auction_end_time }} IST</span>
                    </div>
                    <div class="c-pos-a-c bg-blue-layer bottom--2">
                        <div class="text-white text-center fl-x-cc h-100">
                            Auction will end in
                            <span class="ml-lg-3">
                                <Countdown :end="dataItem.auction_end_date_counter_format"></Countdown>
                            </span>
                        </div>
                    </div>
                    <div>
                        <btn @click="$emit('cancel', dataItem.auction_id)" text="Cancel Auction" design="basic-a"
                             size="md"
                             color="danger" class="px-3 border-r--2 mr-2"
                             v-if="(currentUser.is_superuser || currentUser.is_bank_admin || currentUser.permission.can_cancel_live_auction)
                             && dataItem.status !== 'Ended'"/>

                        <btn @click="$emit('view', dataItem.auction_id)" text="View Details" design="basic-a" size="md"
                             color="secondary" class="px-3 border-r--2"/>
                    </div>

                </div>

            </div>
        </div>
        <!--Responsive-->
        <div class="d-lg-none">
            <div class="gr-3-cols">
                <div class="fl-gr-1 b-1 py-2">
                    <div class="fl-x fl-j-c fl-a-c">
                        <div class="pos-r pr-2 py-2">
                            <div class=""></div>
                            Sl No
                        </div>
                    </div>
                </div>
                <div class="fl-gr-1 b-1 py-2">
                    <div class="fl-x fl-j-s fl-a-c">
                        <div class="pos-r pr-2 pl-3 ">
                            <div class=""></div>
                            Auction ID
                        </div>
                    </div>
                </div>
                <div class="fl-gr-1 b-1 py-2">
                    <div class="fl-x fl-j-c fl-a-c">
                        <div class="pos-r pl-3">
                            <div class=" "></div>
                            Account/Ref No
                        </div>
                    </div>
                </div>

            </div>
            <div class="b-1 bs-3">
                <div class="gr-3-cols font-inter-medium c-line-bottom">
                    <!--                <div class="line-bottom"></div>-->
                    <div class="b-1">
                        <div class="fl-x fl-j-c fl-a-c">
                            <div class="pos-r py-2 pr-5 pl-5 ">
                                <div class=""></div>
                                {{ index + 1 }}
                            </div>
                        </div>
                    </div>
                    <div class="b-1">
                        <div class="pos-r py-2 pl-3">
                            <div class=""></div>
                            {{ dataItem.auction_id }}
                        </div>
                    </div>
                    <div class="b-1">
                        <div class="pos-r py-2 pl-3">
                            <div class=""></div>
                            {{ dataItem.ref_no }}
                        </div>
                    </div>
                </div>
                <div class="row b-1 fs--1 m-0">
                    <span class="b-1 w-100 col-12 py-2">
                        <span>Reserve Price : &nbsp;</span>&#8377;&nbsp;{{ dataItem.reverse_price }}
                    </span>
                    <span class="b-1 w-100 col-12 py-2">
                    <div class="p-1">
                        Property Type &nbsp;&nbsp;: <span class="ml-2">{{ dataItem.property_category }}</span>
                    </div>
                </span>
                    <span class="b-1 w-100 col-12 py-2">
                    <div class="p-1">
                        H1 Bid Amount &nbsp;&nbsp;: <span class="ml-2">₹ {{ dataItem.h1_bid_amount }}</span>
                    </div>
                </span>

                    <span class="b-1 w-100 col-12 py-2">
                    <div class="p-1">
                        Branch &nbsp;&nbsp;: <span class="ml-2">{{ dataItem.branch }}</span>
                    </div>
                </span>
                    <span class="b-1 col-12 py-2">
                    <div class="p-1">
                         Auction Date &nbsp;&nbsp; :<span class="ml-2">
                    {{ dataItem.auction_start_date }}</span>
                    </div>
                </span>
                    <span class="b-1 col-12 py-2">
                    <div class="p-1">
                         Start Time  &nbsp;&nbsp;:<span class="ml-2">
                    {{ dataItem.auction_start_time }} IST</span>
                    </div>
                </span>
                    <span class="b-1 col-12 py-2 py-2">
                    <div class="p-1">
                         Actual End Time  &nbsp;&nbsp;: <span class="ml-2"><br/>
                    {{ dataItem.auction_end_date }} {{ dataItem.auction_end_time }} IST</span>
                    </div>
                </span>
                    <span class="b-1 col-12 p-0 mt-3">

                    <div class="c-pos-a-c bg-blue-layer bottom--2">
                        <div class="text-white text-center bg-primary h-100">
                            Auction will end in
                            <span>
                                <Countdown class="c-countdown"
                                           :end="dataItem.auction_end_date_counter_format"></Countdown>
                            </span>
                        </div>
                    </div>
                    </span>
                    <span class="b-1 col-12">
                    <div class="p-1 fl-x fl-j-c py-3">
                        <div class="btn-group">
                            <button @click="$emit('cancel', dataItem.auction_id)"
                                    v-if="(currentUser.is_superuser || currentUser.is_bank_admin || currentUser.permission.can_cancel_live_auction)
                                        && dataItem.status !== 'Ended'"
                                    class="lego-btn icon-danger btn- hover-bg-1 btn-color-primary bs-1 bs-1-h">
                                <i class="fa fa-close mr-2"></i>
                                Cancel Auction
                            </button>
                            <button @click="$emit('view', dataItem.auction_id)"
                                    class="lego-btn icon-primary hover-bg-1 btn- btn-color-primary bs-1 bs-1-h">
                                <i class="fa fa-eye mr-2"></i>
                                View Details
                            </button>
                        </div>
                    </div>
                </span>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import Countdown from 'vuejs-countdown';
import { mapGetters } from 'vuex';

export default {
    name : 'LiveAuctionItemCard',

    components : { Countdown },

    computed : {
        ...mapGetters(['currentUser'])
    },

    props : {
        interested : {
            type    : Boolean,
            default : false
        },
        dataItem : {
            type : Object
        },
        index : {
            type : String
        }
    },

    methods : {
        toggleInterestStatus () {
            this.interested = !this.interested;
        }
    }
};
</script>

<style scoped lang="scss">
.logo-container {
    width: 2.5rem;
    height: auto;
    @media (min-width: 992px) {
        width: 3.5rem;
    }
}

.bg-blue-layer {
    background: var(--bg-primary);
    height: 5rem !important;
    @media(min-width: 992px) {
        background: url("../../../assets/web/layers/layer-auction-card.png");
        //background-size: cover;
        background-size: contain;
        background-repeat: no-repeat;
        bottom: -1.3125rem !important;
        width: 100%;
        height: 2rem;
        //was on small screens -upto this
        min-width: 30vw;
        height: 4rem !important;
        width: unset;
    }
}

.bottom--2 {
    bottom: -1rem;
}

.c-pos-a-c {
    @media (min-width: 992px) {
        position: absolute;
        left: 50%;
        //top:4rem;
        transform: translate(-50%);
    }
}

.logo-container-1 {
    width: 1.5rem;

    img {
        max-height: 2.5rem;
    }

    //height: auto;
    @media (min-width: 992px) {
        width: 2rem;
        img {
            max-height: unset;
        }
    }
}

.c-card {
    span {
        //font-weight: 550;
        font-family: inter-medium;
    }

    .logo-container {
        width: 2.5rem;
        height: auto;
        @media (min-width: 992px) {
            width: 3.5rem;
        }
    }
}

.address-text {
    font-family: 'inter-regular' !important;
}

.img-filter {
    filter: grayscale(100%);
}

.line-right {
    position: absolute;
    right: 0;
    top: 50%;
    width: 1px;
    height: 3.125rem;
    transform: translateY(-50%);
    background-color: rgba(128, 128, 128, 0.3);
}
</style>
