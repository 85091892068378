<template>
    <!--    <s-form class="filter-grid">-->
    <!--        <div class="pt-2 d-sm-none">-->
    <!--            <p class="font-inter-medium mt-lg-2 pl-lg-4">Filters</p>-->
    <!--        </div>-->

    <!--        <validated-select class="ml-lg-2 c-form-input order-3 border-r-0" v-model="filter.bank" @blur="setFilter"-->
    <!--                          placeholder="Bank" :options="bankOptions" />-->

    <s-form>
        <div class="row">
            <div v-if="currentUser.is_superuser || currentUser.is_ventura_admin" class="col-lg-4 ml-lg-4 px-1">
                <validated-select class=" c-input-select-b pl-4 pr-4 pl-lg-0 order-3 border-r-0" v-model="filter.bank"
                                  @blur="setFilter" placeholder="Bank/FI’s" :options="bankOptions"/>
            </div>

            <div class="col-lg-3 col-md ml-lg-4 order-lg-2 order-3 px-1">

                <validated-select class=" c-input-select-b order-3 pl-4 pr-4 pl-lg-0 border-r-0" v-model="filter.state"
                                  @blur="setFilter"
                                  placeholder="State" :options="stateOptions" @input="setDistrict(filter.state)"/>
            </div>

            <div class=" col-lg-4 col-md order-lg-2 order-3 px-1">

                <validated-select class=" c-input-select-b order-4 border-none pl-4 pr-4 pl-lg-0"
                                  v-model="filter.district"
                                  placeholder="District" :options="districtOptions" @input="setFilter"/>

            </div>
        </div>

        <!--        <div class="d-sm-none d-md-none">-->

        <!--            <div class="col-3 col-lg-3 col-md pl-2 order-lg-2 order-3 px-1">-->
        <!--                <validated-input class="ml-lg-2 order-1 border-r-0" v-model="filter.ext_search"-->
        <!--                                 placeholder="Search(Auction Id/ Name)" @input="setFilter"/>-->
        <!--            </div>-->

        <!--        </div>-->
        <div class="d-md-none">

            <div class="col-lg-3 col-md order-lg-2 order-3 px-3">
                <validated-input class="ml-lg-2 order-1 border-r-0" v-model="filter.ext_search"
                                 placeholder="Search(Auction Id/ Name)" @input="setFilter"/>
            </div>

        </div>

    </s-form>

</template>

<script>
import urls from '../../../data/urls';
import axios from 'secure-axios';
import { mapGetters } from 'vuex';

export default {
    name     : 'LiveAuctionFilter',
    computed : {
        ...mapGetters(['currentUser'])
    },

    data () {
        return {
            status        : '',
            statusOptions : [
                { label : 'All', value : 'All' },
                { label : 'Published', value : 'Created' },
                { label : 'Cancelled', value : 'Cancelled' },
                { label : 'Freezed', value : 'Freezed' },
                { label : 'Started', value : 'Started' },
                { label : 'Ended', value : 'Ended' }
            ],

            stateOptions       : [],
            districtOptions    : [],
            AllDistrictOptions : [],
            bankOptions        : [],

            filter : {
                state      : '',
                district   : '',
                ext_search : ''
            }

        };
    },

    beforeMount () {
        this.loadBank();
        this.loadStates();
        this.loadDistrict();
    },

    methods : {
        async loadBank () {
            const response = await axios.get(urls.auction.bank.bankOptions);
            this.bankOptions = response.data.data;
            this.bankOptions.unshift({ label : 'All', value : 'All' });
        },

        async loadStates () {
            const response = await axios.get(urls.auction.auctionList.stateVueSelect + '?type=Text');
            this.stateOptions = response.data.data;
            this.stateOptions.unshift({ label : 'All', value : 'All' });
        },

        async loadDistrict () {
            const response = await axios.get(urls.auction.auctionList.districtVueSelect + '?type=Text');
            this.AllDistrictOptions = response.data.data;
            this.districtOptions = response.data.data;
            this.districtOptions.unshift({ label : 'All', value : 'All' });
        },

        setDistrict (state) {
            const filterValues = this.AllDistrictOptions.filter(ele => ele.state === state);
            this.filter.district = '';
            if (filterValues.length > 0) {
                filterValues.unshift({ label : 'All', value : 'All', state : '' });
            }
            this.districtOptions = [...filterValues];
        },

        setFilter () {
            this.$emit('filterUpdate', this.filter);
        }
    }
};
</script>

<style scoped lang="scss">
.filter-grid {
    display: grid;
    grid-template-columns: 1fr repeat(3, 2fr) 3fr;
}

.filter-grid {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    @media(min-width: 992px) {
        grid-template-columns: 1fr repeat(3, 2fr) 3fr;
    }
}
</style>
